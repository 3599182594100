import React, { useState } from 'react';
import { Card, List, Typography, Tag, Space, Modal } from 'antd';
import { FileTextOutlined, UserOutlined, CalendarOutlined } from '@ant-design/icons';
import { sectionHeaderStyle, getFileIcon } from './utils';

const { Title, Text } = Typography;

const CaseFiles = ({ files }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileClick = (file) => {
    setSelectedFile(file);
    setModalVisible(true);
  };

  return (
    <Card 
      title={
        <div style={{ ...sectionHeaderStyle, backgroundColor: '#E6F7FF' }}>
          <Title level={5}><FileTextOutlined /> Case Files</Title>
        </div>
      }
      className="w-full"
      styles={{ header:{ padding: 0 }}}
    >
      <List
        itemLayout="horizontal"
        dataSource={files}
        renderItem={item => (
          <List.Item
            actions={[
              <a key="view" onClick={() => handleFileClick(item)}>View Details</a>
            ]}
          >
            <List.Item.Meta
              avatar={getFileIcon(item.type)}
              title={<a onClick={() => handleFileClick(item)}>{item.title}</a>}
              description={
                <Space direction="vertical" size="small">
                  <Text type="secondary">File: {item.name}</Text>
                  <Space>
                    <Tag icon={<UserOutlined />} color="blue">{item.uploader}</Tag>
                    <Tag icon={<CalendarOutlined />} color="green">{item.dateUploaded}</Tag>
                  </Space>
                </Space>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title={selectedFile?.title}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {selectedFile && (
          <>
            <p><strong>File Name:</strong> {selectedFile.name}</p>
            <p><strong>Uploader:</strong> {selectedFile.uploader}</p>
            <p><strong>Date Uploaded:</strong> {selectedFile.dateUploaded}</p>
            <p><strong>Summary:</strong></p>
            <ul>
              {selectedFile.summary.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </>
        )}
      </Modal>
    </Card>
  );
};

export default CaseFiles;
