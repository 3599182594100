import React, { useState } from 'react';
import { Button, Space, message, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { sendMediationForm } from './api';

const { TextArea } = Input;

const SendMediationForm = ({ caseId, onSuccess }) => {
  const [agreementText, setAgreementText] = useState('');

  const handleSendForm = async () => {
    if (agreementText.trim()) {
      try {
        const result = await sendMediationForm(caseId, agreementText);
        if (result.success) {
          message.success('Mediation form sent successfully!');
          setAgreementText('');
          onSuccess(); // Call the callback function to refresh case details
        } else {
          message.error('Failed to send mediation form. Please try again.');
        }
      } catch (error) {
        console.error('Error sending mediation form:', error);
        message.error('Failed to send mediation form');
      }
    } else {
      message.error('Please enter the agreed mediation terms before sending.');
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <TextArea
        rows={6}
        value={agreementText}
        onChange={(e) => setAgreementText(e.target.value)}
        placeholder="Type the agreed mediation terms here and our AI will generate the digital form to be signed by the parties"
      />
      <Button 
        type="primary" 
        icon={<SendOutlined />} 
        onClick={handleSendForm}
        disabled={!agreementText.trim()}
        style={{ width: '100%' }}
      >
        Send Mediation Agreement to be Signed by Parties
      </Button>
    </Space>
  );
};

export default SendMediationForm;