import React, { useState } from 'react';
import { Input, Button, List, Card, Modal, Typography, Spin, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { searchNatijaAI } from './api';

const { Title, Text, Paragraph } = Typography;

const SearchNatijaAI = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    if (searchQuery.trim()) {
      setIsLoading(true);
      try {
        const results = await searchNatijaAI(searchQuery);
        setSearchResults(results);
      } catch (error) {
        message.error('An error occurred while searching. Please try again.');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleCardClick = (result) => {
    setSelectedResult(result);
    setModalVisible(true);
  };

  return (
    <div style={{ padding: '24px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
      <Card style={{ marginBottom: '24px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <Title level={2} style={{ textAlign: 'center', marginBottom: '24px', color: '#1890ff' }}>Research</Title>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
          <Input
            placeholder="Enter your search query"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: '60%', marginRight: '16px', borderRadius: '4px' }}
            onPressEnter={handleSearch}
            size="large"
          />
          <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch} size="large" style={{ borderRadius: '4px' }}>
            Search
          </Button>
        </div>
      </Card>
      {isLoading && (
        <div style={{ textAlign: 'center', marginTop: '48px' }}>
          <Spin size="large" />
          <Paragraph style={{ marginTop: '16px', fontSize: '16px' }}>
            Natija's AI is researching, hold on for a few seconds...
          </Paragraph>
        </div>
      )}
      {!isLoading && searchResults && (
        <>
          <Card
            title={<Title level={4} style={{ margin: 0 }}>Summary</Title>}
            style={{ marginBottom: '24px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
            styles={{ header:{ backgroundColor: '#bae7ff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}}
          >
            <ul style={{ paddingLeft: '20px' }}>
              {searchResults.summaryAnswer.map((point, index) => (
                <li key={index}>
                  <Paragraph>{point}</Paragraph>
                </li>
              ))}
            </ul>
          </Card>
          <Title level={4} style={{ marginBottom: '16px', color: '#1890ff' }}>Detailed Results</Title>
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
            dataSource={searchResults.detailedResults}
            renderItem={(item) => (
              <List.Item>
                <Card
                  title={<Text strong>{item.title}</Text>}
                  hoverable
                  onClick={() => handleCardClick(item)}
                  style={{ borderRadius: '8px', height: '100%' }}
                  styles={{ header:{ backgroundColor: '#e6f7ff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}}

                >
                  <ul style={{ paddingLeft: '20px' }}>
                    {item.summary.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Card>
              </List.Item>
            )}
          />
        </>
      )}
      <Modal
        title={selectedResult?.title}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={800}
      >
        <Text>{selectedResult?.fullText}</Text>
      </Modal>
    </div>
  );
};

export default SearchNatijaAI;
