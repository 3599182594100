import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Select, Typography, Empty, Spin, message } from 'antd';
import Sidebar from './sidebar';
import CaseDetails from './case-details';
import MediationTimeline from './mediation-timeline';
import CaseFiles from './case-files';
import MediatorChat from './mediator-chat';
import SearchNatijaAI from './search-natija-ai';
import MediatorProfile from './mediator-profile';
import MediatorTools from './MediatorTools';
import Login from './Login';
import { 
  fetchCases, 
  fetchCaseDetails, 
  fetchCaseFiles, 
  fetchMediatorStats, 
  fetchMediatorProfile,
  checkSessionValidity,
  signOut
} from './api';

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [cases, setCases] = useState([]);
  const [selectedCase, setSelectedCase] = useState(null);
  const [caseDetails, setCaseDetails] = useState(null);
  const [caseFiles, setCaseFiles] = useState([]);
  const [overviewStats, setOverviewStats] = useState({});
  const [currentPage, setCurrentPage] = useState('dashboard');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [mediatorName, setMediatorName] = useState('');
  const [mediatorProfile, setMediatorProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMediatorDetails = async () => {
    try {
      setIsLoading(true);
      const stats = await fetchMediatorStats();
      setOverviewStats(stats);
      setMediatorName("My Profile");
      const profile = await fetchMediatorProfile();
      setMediatorProfile(profile);
      const allCases = await fetchCases();
      setCases(allCases);
      if (allCases.length > 0) {
        await handleCaseChange(allCases[0].id);
      }
    } catch (error) {
      console.error('Error fetching mediator details:', error);
      handleLogout();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const validateSession = async () => {
      setIsLoading(true);
      const sessionValid = await checkSessionValidity();
      if (sessionValid) {
        setIsLoggedIn(true);
        await fetchMediatorDetails();
      } else {
        handleLogout();
      }
      setIsLoading(false);
    };

    validateSession();
  }, []);

  const handleCaseChange = async (caseId) => {
    setIsLoading(true);
    setSelectedCase(caseId);
    try {
      const details = await fetchCaseDetails(caseId);
      setCaseDetails(details);
      const files = await fetchCaseFiles(caseId);
      setCaseFiles(files);
    } catch (error) {
      console.error('Error fetching case details:', error);
      message.error('Failed to fetch case details');
    } finally {
      setIsLoading(false);
    }
  };

  const refreshCaseDetails = async () => {
    if (selectedCase) {
      try {
        const details = await fetchCaseDetails(selectedCase);
        setCaseDetails(details);
      } catch (error) {
        console.error('Error refreshing case details:', error);
        message.error('Failed to refresh case details');
      }
    }
  };

  const handleMenuSelect = ({ key }) => {
    setCurrentPage(key);
  };

  const handleLogin = (data) => {
    setIsLoggedIn(true);
    setMediatorName(data.name);
    fetchMediatorDetails();
  };

  const handleLogout = () => {
    signOut().then(() => {
      setIsLoggedIn(false);
      setMediatorName('');
      setCases([]);
      setSelectedCase(null);
      setCaseDetails(null);
      setCaseFiles([]);
      setOverviewStats({});
      setCurrentPage('dashboard');
      setMediatorProfile(null);
      console.log('Logout successful done');
    });
  };

  const onProfileUpdate = (updatedProfile) => {
    setMediatorProfile(updatedProfile);
    setMediatorName(updatedProfile.name);
    message.success('Profile updated successfully');
  };

  const renderDashboard = () => (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Title level={5}>Select Case</Title>
        {cases.length > 0 ? (
          <Select
            style={{ width: '100%' }}
            placeholder="Select a case"
            value={selectedCase}
            onChange={handleCaseChange}
          >
            {cases.map(c => (
              <Option key={c.id} value={c.id}>{c.name}</Option>
            ))}
          </Select>
        ) : (
          <Text type="secondary">No cases available at the moment.</Text>
        )}
      </Col>
      {cases.length > 0 ? (
        selectedCase && caseDetails && (
          <>
            <Col span={24}>
              <MediatorTools caseId={selectedCase} onActionSuccess={refreshCaseDetails} />
            </Col>
            <Col xs={24} lg={12}>
              <CaseDetails caseDetails={caseDetails} />
            </Col>
            <Col xs={24} lg={12}>
              <MediatorChat caseId={selectedCase} />
            </Col>
            <Col xs={24} lg={12}>
              <MediationTimeline timeline={caseDetails.timeline} caseId={selectedCase} />
            </Col>
            <Col xs={24} lg={12}>
              <CaseFiles files={caseFiles} />
            </Col>
          </>
        )
      ) : (
        <Col span={24}>
          <Empty
            description={
              <span>
                No cases available at the moment. Please revisit later.
              </span>
            }
          />
        </Col>
      )}
    </Row>
  );

  const renderContent = () => {
    switch (currentPage) {
      case 'search':
        return <SearchNatijaAI />;
      case 'profile':
        return mediatorProfile ? (
          <MediatorProfile 
            mediatorData={mediatorProfile} 
            overviewStats={overviewStats}
            onProfileUpdate={onProfileUpdate}
          />
        ) : (
          <div>Loading profile...</div>
        );
      default:
        return renderDashboard();
    }
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        mediatorName={mediatorName}
        onMenuSelect={handleMenuSelect}
        onLogout={handleLogout}
      />
      <Layout className="site-layout">
        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Spin size="large" />
            </div>
          ) : (
            renderContent()
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;