import React, { useState } from 'react';
import { Form, Input, Button, message, Card, Typography, Layout } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { requestOTP, verifyOTP } from './api';

const { Title, Text } = Typography;
const { Content } = Layout;

const Login = ({ onLogin }) => {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState('');

  const handleRequestOTP = async (values) => {
    try {
      await requestOTP(values.email);
      setIsOtpSent(true);
      setEmail(values.email);
      message.success('OTP sent to your email');
    } catch (error) {
      message.error('Failed to send OTP');
    }
  };

  const handleVerifyOTP = async (values) => {
    try {
      const data = await verifyOTP(email, values.otp);
      onLogin(data);
      message.success('Logged in successfully');
    } catch (error) {
      message.error('Invalid OTP');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh', background: '#f0f2f5' }}>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card
          style={{
            width: '100%',
            maxWidth: '400px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          }}
        >
          <div style={{ textAlign: 'center', marginBottom: '24px' }}>
            <img
              src="/natija-logo.png"
              alt="Natija Logo"
              style={{ width: '120px', marginBottom: '16px' }}
            />
            <Title level={3} style={{ margin: 0, color: '#1890ff' }}>
              Mediator Dashboard
            </Title>
          </div>
          {!isOtpSent ? (
            <Form onFinish={handleRequestOTP} layout="vertical">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please enter a valid email!' },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  Request OTP
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Form onFinish={handleVerifyOTP} layout="vertical">
              <Form.Item
                name="otp"
                rules={[{ required: true, message: 'Please input the OTP!' }]}
              >
                <Input prefix={<LockOutlined />} placeholder="OTP" size="large" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" size="large" block>
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
          )}
          <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
            {isOtpSent
              ? "Enter the OTP sent to your email"
              : "We'll send you a one-time password to your email"}
          </Text>
        </Card>
      </Content>
    </Layout>
  );
};

export default Login;
