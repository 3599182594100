import React from 'react';
import { Layout, Menu, Image, Typography, Button } from 'antd';
import { 
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
  UserOutlined,
  SearchOutlined
} from '@ant-design/icons';

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar = ({ collapsed, setCollapsed, mediatorName, onMenuSelect, onLogout }) => {
  const menuItems = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
    },
    // {
    //   key: 'search',
    //   icon: <SearchOutlined />,
    //   label: 'Research',
    // },
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: mediatorName,
    },
  ];

  return (
    <Sider 
      trigger={null} 
      collapsible 
      collapsed={collapsed} 
      width={250} 
      style={{ background: '#fff', display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Image
          src="/natija-logo.png"
          alt="Natija Logo"
          preview={false}
          style={{ height: 40, marginBottom: collapsed ? 0 : 8 }}
        />
        {!collapsed && (
          <Title level={4} style={{ margin: 0, textAlign: 'center' }}>
            Mediator Dashboard
          </Title>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0 16px' }}>
        <Button 
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{ fontSize: '16px', width: 64, height: 64 }}
        />
      </div>
      <Menu 
        theme="light" 
        mode="inline" 
        defaultSelectedKeys={['dashboard']} 
        onSelect={onMenuSelect} 
        items={menuItems}
        style={{ flex: 1 }}
      />
      <Menu
        theme="light"
        mode="inline"
        selectable={false}
        style={{ borderTop: '1px solid #f0f0f0' }}
      >
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          onClick={onLogout}
          style={{ color: 'red' }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
