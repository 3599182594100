import React, { useState } from 'react';
import { Card, Descriptions, Tag, Space, Typography, Divider, Row, Col, Statistic, Tooltip } from 'antd';
import { InfoCircleOutlined, TeamOutlined, FlagOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { sectionHeaderStyle } from './utils';

const { Title, Text } = Typography;

const CaseDetails = ({ caseDetails }) => {
  const [hoveredParty, setHoveredParty] = useState(null);

  return (
    <Card 
      title={
        <div style={{ ...sectionHeaderStyle, backgroundColor: '#E6F4FF' }}>
          <Title level={5}><InfoCircleOutlined /> Case Details</Title>
        </div>
      }
      className="w-full"
      styles={{ header: { padding: 0 } }}
    >
      <Descriptions layout="vertical" column={1}>
        <Descriptions.Item label={<Text strong>Case Name</Text>}>
          <Text>{caseDetails.name}</Text>
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Case Type</Text>}>
          <Tag color="blue">{caseDetails.type}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Status</Text>}>
          <Tag color={caseDetails.status === 'Ongoing' ? 'green' : 'red'}>
            {caseDetails.status}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label={<Text strong>Parties Involved</Text>}>
          <Space wrap>
            {Object.entries(caseDetails.parties).map(([partyName, partyDetails]) => (
              <Tooltip
                key={partyName}
                title={
                  <div>
                    <p>{partyDetails}</p>
                  </div>
                }
                mouseEnterDelay={0.5}
                mouseLeaveDelay={0.2}
              >
                <Tag
                  color="purple"
                  icon={<TeamOutlined />}
                  onMouseEnter={() => setHoveredParty(partyName)}
                  onMouseLeave={() => setHoveredParty(null)}
                  style={{ cursor: 'pointer' }}
                >
                  {partyName}
                </Tag>
              </Tooltip>
            ))}
          </Space>
        </Descriptions.Item>
      </Descriptions>

      <Divider style={{ margin: '12px 0' }} />

      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title="Mediation Sessions Completed"
            value={caseDetails.completedSessions}
            prefix={<CheckCircleOutlined />}
            valueStyle={{ color: '#3f8600' }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Mediation Sessions Left"
            value={caseDetails.remainingSessions}
            prefix={<ClockCircleOutlined />}
            valueStyle={{ color: '#cf1322' }}
          />
        </Col>
      </Row>

      <Divider style={{ margin: '12px 0' }} />

      <Descriptions layout="vertical" column={1}>
        <Descriptions.Item 
          label={<Text strong><FlagOutlined /> Case Details</Text>}
        >
          <Text>{caseDetails.details}</Text>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default CaseDetails;
