import React from 'react';
import { Card, Typography, Collapse } from 'antd';
import { sectionHeaderStyle } from './utils';
import ScheduleMeeting from './ScheduleMeeting';
import SendMediationForm from './SendMediationForm';
import RequestDocument from './RequestDocument';

const { Title } = Typography;
const { Panel } = Collapse;

const MediatorTools = ({ caseId, onActionSuccess }) => {
  // Custom styles for the Collapse component
  const collapseStyle = {
    backgroundColor: 'white',
    border: 'none',
  };

  // Custom styles for the Panel components
  const panelStyle = {
    marginBottom: '16px',
    border: '1px solid #d9d9d9',
    borderRadius: '2px',
  };

  return (
    <Card 
      title={
        <div style={{ ...sectionHeaderStyle, backgroundColor: '#E6F7FF' }}>
          <Title level={5}>Mediator Tools</Title>
        </div>
      }
      className="w-full"
      styles={{ header: { padding: 0 } }}
    >
      <Collapse style={collapseStyle}>
        <Panel header="Schedule Meeting" key="1" style={panelStyle}>
          <ScheduleMeeting caseId={caseId} onSuccess={onActionSuccess} />
        </Panel>
        <Panel header="Send Mediation Agreement" key="2" style={panelStyle}>
          <SendMediationForm caseId={caseId} onSuccess={onActionSuccess} />
        </Panel>
        <Panel header="Request Document" key="3" style={panelStyle}>
          <RequestDocument caseId={caseId} onSuccess={onActionSuccess} />
        </Panel>
      </Collapse>
    </Card>
  );
};

export default MediatorTools;
