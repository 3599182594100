import React, { useState } from 'react';
import { Card, Row, Col, Typography, Tag, Avatar, Divider, Statistic, Input, Button, Upload, message, Select } from 'antd';
import { MailOutlined, PhoneOutlined, HomeOutlined, GlobalOutlined, LinkedinOutlined, UserOutlined, FileOutlined, TeamOutlined, CheckCircleOutlined, EditOutlined, SaveOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { updateMediatorProfile } from './api';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const MediatorProfile = ({ mediatorData, overviewStats, onProfileUpdate }) => {
  const [personalEditMode, setPersonalEditMode] = useState(false);
  const [professionalEditMode, setProfessionalEditMode] = useState(false);
  const [editedData, setEditedData] = useState(mediatorData);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleInputChange = (field, value) => {
    setEditedData(prev => ({ ...prev, [field]: value }));
  };

  const handleTagChange = (field, tags) => {
    setEditedData(prev => ({ ...prev, [field]: tags }));
  };

  const handleSave = async (section) => {
    setIsUpdating(true);
    try {
      const changedData = {};
      Object.keys(editedData).forEach(key => {
        if (JSON.stringify(editedData[key]) !== JSON.stringify(mediatorData[key])) {
          changedData[key] = editedData[key];
        }
      });

      if (Object.keys(changedData).length > 0) {
        const response = await updateMediatorProfile(changedData);
        if (response && response.message === "Profile updated successfully") {
          // message.success('Profile updated successfully');
          onProfileUpdate(editedData);
          if (section === 'personal') setPersonalEditMode(false);
          if (section === 'professional') setProfessionalEditMode(false);
        } else {
          throw new Error('Update failed');
        }
      } else {
        message.info('No changes to update');
        if (section === 'personal') setPersonalEditMode(false);
        if (section === 'professional') setProfessionalEditMode(false);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancel = (section) => {
    setEditedData(mediatorData);
    if (section === 'personal') setPersonalEditMode(false);
    if (section === 'professional') setProfessionalEditMode(false);
  };

  const cardStyle = {
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const renderPersonalInfo = () => (
    <Card 
      style={cardStyle}
      styles={{ header: { backgroundColor: '#d6e4ff' } }}
      title={<Title level={4}>Personal Information</Title>}
      extra={
        personalEditMode ? (
          <>
            <Button icon={<SaveOutlined />} onClick={() => handleSave('personal')} style={{ marginRight: 8 }} loading={isUpdating}>Save</Button>
            <Button icon={<CloseOutlined />} onClick={() => handleCancel('personal')} disabled={isUpdating}>Cancel</Button>
          </>
        ) : (
          <Button icon={<EditOutlined />} onClick={() => setPersonalEditMode(true)}>Edit</Button>
        )
      }
    >
      <div style={{ textAlign: 'center', marginBottom: 24 }}>
        {personalEditMode ? (
          <Upload
            beforeUpload={() => false}
            onChange={(info) => {
              if (info.file.status !== 'uploading') {
                handleInputChange('profile_picture', info.file);
              }
            }}
          >
            <Button icon={<UploadOutlined />}>Upload New Picture</Button>
          </Upload>
        ) : (
          <Avatar size={200} src={editedData.profile_pic} icon={<UserOutlined />} />
        )}
        <Title level={2} style={{ marginTop: 16, marginBottom: 0 }}>{editedData.name}</Title>
        <Text type="secondary">Mediating since {editedData.mediating_from_year}</Text>
      </div>
      <Divider />
      <Paragraph>
        <MailOutlined /> {editedData.email}<br />
        <PhoneOutlined /> {editedData.phone_number}<br />
        <HomeOutlined /> {personalEditMode ? (
          <Input 
            value={editedData.address} 
            onChange={(e) => handleInputChange('address', e.target.value)} 
          />
        ) : editedData.address}
      </Paragraph>
      {personalEditMode ? (
        <>
          <Input 
            prefix={<GlobalOutlined />}
            value={editedData.personal_web_page} 
            onChange={(e) => handleInputChange('personal_web_page', e.target.value)}
            placeholder="Personal Website URL"
          />
          <Input 
            prefix={<LinkedinOutlined />}
            value={editedData.linkedin_url} 
            onChange={(e) => handleInputChange('linkedin_url', e.target.value)}
            placeholder="LinkedIn Profile URL"
            style={{ marginTop: 8 }}
          />
        </>
      ) : (
        <>
          {editedData.personal_web_page && (
            <Paragraph>
              <GlobalOutlined /> <a href={editedData.personal_web_page} target="_blank" rel="noopener noreferrer">Personal Website</a>
            </Paragraph>
          )}
          {editedData.linkedin_url && (
            <Paragraph>
              <LinkedinOutlined /> <a href={editedData.linkedin_url} target="_blank" rel="noopener noreferrer">LinkedIn Profile</a>
            </Paragraph>
          )}
        </>
      )}
      <Divider />
      {personalEditMode ? (
        <TextArea 
          value={editedData.personal_note} 
          onChange={(e) => handleInputChange('personal_note', e.target.value)}
          rows={4}
        />
      ) : (
        <Paragraph>{editedData.personal_note}</Paragraph>
      )}
    </Card>
  );

  const renderProfessionalDetails = () => (
    <Card 
      style={cardStyle}
      styles={{ header: { backgroundColor: '#d6e4ff' } }}
      title={<Title level={4}>Professional Details</Title>}
      extra={
        professionalEditMode ? (
          <>
            <Button icon={<SaveOutlined />} onClick={() => handleSave('professional')} style={{ marginRight: 8 }} loading={isUpdating}>Save</Button>
            <Button icon={<CloseOutlined />} onClick={() => handleCancel('professional')} disabled={isUpdating}>Cancel</Button>
          </>
        ) : (
          <Button icon={<EditOutlined />} onClick={() => setProfessionalEditMode(true)}>Edit</Button>
        )
      }
    >
      <Row gutter={[16, 16]}>
        {['education', 'certifications', 'services_offered', 'areas_of_expertise', 'preferred_hours_for_mediation_sessions', 'languages'].map((field) => (
          <Col span={24} key={field}>
            <Title level={5}>{field.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</Title>
            {professionalEditMode ? (
              <Select
                mode="tags"
                style={{ width: '100%' }}
                placeholder="Add tags"
                value={editedData[field]}
                onChange={(tags) => handleTagChange(field, tags)}
              >
                {editedData[field].map(item => <Option key={item}>{item}</Option>)}
              </Select>
            ) : (
              editedData[field].map(item => <Tag color="blue" key={item}>{item}</Tag>)
            )}
          </Col>
        ))}
        <Col span={24} style={{ marginTop: '16px' }}>
          <Title level={5}>Time Zone</Title>
          {professionalEditMode ? (
            <Select
              style={{ width: '100%' }}
              value={editedData.time_zone}
              onChange={(value) => handleInputChange('time_zone', value)}
            >
              <option value="GMT-5 (Eastern Time)">Eastern Time (ET)</option>
              <option value="GMT-6 (Central Time)">Central Time (CT)</option>
              <option value="GMT-7 (Mountain Time)">Mountain Time (MT)</option>
              <option value="GMT-8 (Pacific Time)">Pacific Time (PT)</option>
              <option value="GMT-9 (Alaska Time)">Alaska Time (AKT)</option>
              <option value="GMT-10 (Hawaii Time)">Hawaii Time (HAT)</option>
              <option value="GMT+5:30 (IST)">India Standard Time (IST)</option>
            </Select>
          ) : (
            <Tag color="blue">{editedData.time_zone}</Tag>
          )}
        </Col>
      </Row>
    </Card>
  );

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} lg={8}>
        {renderPersonalInfo()}
      </Col>
      <Col xs={24} lg={16}>
        {renderProfessionalDetails()}
      </Col>
      <Col span={24}>
        <Card 
          style={cardStyle}
          styles={{ header: { backgroundColor: '#d6e4ff' } }}
          title={<Title level={4}>Your overview with Natija</Title>}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={6}>
              <Statistic
                title="Open Mediations"
                value={overviewStats.currentCases}
                prefix={<FileOutlined />}
                valueStyle={{ color: '#3f8600' }}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Statistic
                title="Total Mediation Sessions"
                value={overviewStats.totalSessions}
                prefix={<TeamOutlined />}
                valueStyle={{ color: '#1890ff' }}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Statistic
                title="Completed Mediations"
                value={overviewStats.completedCases}
                prefix={<CheckCircleOutlined />}
                valueStyle={{ color: '#faad14' }}
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Statistic
                title="Success Rate"
                value={((overviewStats.completedCases / (overviewStats.completedCases + overviewStats.currentCases)) * 100).toFixed(2)}
                suffix="%"
                precision={2}
                prefix={<CheckCircleOutlined />}
                valueStyle={{ color: '#722ed1' }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default MediatorProfile;
