import { FileTextOutlined } from '@ant-design/icons';

export const sectionHeaderStyle = {
  padding: '8px 16px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  marginBottom: '-1px', // To overlap with Card border
};

export const getFileIcon = (fileType) => {
  switch (fileType) {
    case 'pdf':
      return <FileTextOutlined style={{ color: '#ff4d4f' }} />;
    case 'image':
      return <FileTextOutlined style={{ color: '#52c41a' }} />;
    case 'document':
      return <FileTextOutlined style={{ color: '#1890ff' }} />;
    default:
      return <FileTextOutlined />;
  }
};
