import React from 'react';
import { Card, Timeline, Typography } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { sectionHeaderStyle } from './utils';

const { Title, Text } = Typography;

const MediationTimeline = ({ timeline, caseId }) => {
  const timelineItems = timeline.map((item, index) => ({
    children: (
      <>
        <Text strong>{item.date}</Text> - {item.event}
      </>
    ),
  }));

  return (
    <Card 
      title={
        <div style={{ ...sectionHeaderStyle, backgroundColor: '#FFF7E6' }}>
          <Title level={5}><ClockCircleOutlined /> Mediation Timeline</Title>
        </div>
      }
      className="w-full"
      styles={{ header:{ padding: 0 }}}
    >
      <Timeline items={timelineItems} />
    </Card>
  );
};

export default MediationTimeline;
