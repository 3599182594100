import React, { useState, useEffect } from 'react';
import { Card, Select, List, Input, Button, Typography, Avatar, message } from 'antd';
import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { sectionHeaderStyle } from './utils';
import { fetchChatOptions, fetchChatMessages, sendChatMessage } from './api';

const { Option } = Select;
const { Title, Text } = Typography;

const MediatorChat = ({ caseId }) => {
  const [chatOptions, setChatOptions] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const loadChatOptions = async () => {
      try {
        const options = await fetchChatOptions(caseId);
        setChatOptions(Array.isArray(options) ? options : []);
        if (options.length > 0) {
          setSelectedChat(options[0].id);
        }
      } catch (error) {
        console.error('Error fetching chat options:', error);
        message.error('Failed to load chat options');
      }
    };
    loadChatOptions();
  }, [caseId]);

  useEffect(() => {
    if (selectedChat) {
      const loadChatMessages = async () => {
        try {
          const chatMessages = await fetchChatMessages(selectedChat);
          setMessages(chatMessages);
        } catch (error) {
          console.error('Error fetching chat messages:', error);
          message.error('Failed to load chat messages');
        }
      };
      loadChatMessages();
    }
  }, [selectedChat]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        const updatedMessages = await sendChatMessage(selectedChat, newMessage);
        setMessages(updatedMessages);
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
        message.error('Failed to send message');
      }
    }
  };

  return (
    <Card 
      title={
        <div style={{ ...sectionHeaderStyle, backgroundColor: '#E6F7FF' }}>
          <Title level={5}>Mediator Chat</Title>
        </div>
      }
      className="w-full"
      styles={{ header:{ padding: 0 }}}
    >
      <Select
        style={{ width: '100%', marginBottom: 16 }}
        placeholder="Select a chat"
        value={selectedChat}
        onChange={setSelectedChat}
      >
        {chatOptions.map(option => (
          <Option key={option.id} value={option.id}>{option.name}</Option>
        ))}
      </Select>
      <List
        className="chat-list"
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.sender}
              description={
                <Text>{item.message}</Text>
              }
            />
          </List.Item>
        )}
        style={{ height: 300, overflowY: 'auto', marginBottom: 16 }}
      />
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onPressEnter={handleSendMessage}
          placeholder="Type a message..."
          style={{ flexGrow: 1, marginRight: 8 }}
        />
        <Button type="primary" icon={<SendOutlined />} onClick={handleSendMessage}>
          Send
        </Button>
      </div>
    </Card>
  );
};

export default MediatorChat;
