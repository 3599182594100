import React, { useState, useEffect } from 'react';
import { Select, Button, Space, message, Input } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { fetchChatOptions, requestDocument } from './api';

const { Option } = Select;
const { TextArea } = Input;

const RequestDocument = ({ caseId, onSuccess }) => {
  const [chatOptions, setChatOptions] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [documentDetails, setDocumentDetails] = useState('');

  useEffect(() => {
    const loadChatOptions = async () => {
      try {
        const options = await fetchChatOptions(caseId);
        setChatOptions(Array.isArray(options) ? options : []);
        if (options.length > 0) {
          setSelectedParty(options[0].id);
        }
      } catch (error) {
        console.error('Error fetching chat options:', error);
        message.error('Failed to load party options');
      }
    };
    loadChatOptions();
  }, [caseId]);

  const handleRequestDocument = async () => {
    if (selectedParty && documentDetails.trim()) {
      try {
        const result = await requestDocument(caseId, selectedParty, documentDetails);
        if (result.success) {
          message.success('Document request sent successfully!');
          setDocumentDetails('');
          onSuccess(); // Call the callback function to refresh case details
        } else {
          message.error('Failed to send document request. Please try again.');
        }
      } catch (error) {
        console.error('Error sending document request:', error);
        message.error('Failed to send document request');
      }
    } else {
      message.warning('Please select a party and provide document details before sending the request.');
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select a party to request document from"
        value={selectedParty}
        onChange={setSelectedParty}
      >
        {chatOptions.map(option => (
          <Option key={option.id} value={option.id}>{option.name}</Option>
        ))}
      </Select>
      <TextArea
        rows={4}
        value={documentDetails}
        onChange={(e) => setDocumentDetails(e.target.value)}
        placeholder="Enter the details of the document you are requesting"
      />
      <Button 
        type="primary" 
        icon={<FileAddOutlined />} 
        onClick={handleRequestDocument}
        disabled={!selectedParty || !documentDetails.trim()}
        style={{ width: '100%' }}
      >
        Request Document
      </Button>
    </Space>
  );
};

export default RequestDocument;