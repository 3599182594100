const API_BASE_URL = '/api';

export const checkSessionValidity = async () => {
  try {
    // Check if the session token is present in local storage
    const sessionToken = localStorage.getItem('session_token');
    if (!sessionToken) {
      return false;
    }
    const result = await fetchWithAuth('/check_session');
    return result.valid;
  } catch (error) {
    return false;
  }
};

export const fetchMediatorProfile = async () => {
  try {
    const response = await fetchWithAuth('/mediator_details');
    return response;
  } catch (error) {
    console.error('Error fetching mediator profile:', error);
    throw error;
  }
};

const fetchWithAuth = async (endpoint, options = {}) => {
  const sessionToken = localStorage.getItem('session_token');
  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };
  if (sessionToken) {
    headers['Authorization'] = `Bearer ${sessionToken}`;
  }

  const response = await fetch(`${API_BASE_URL}${endpoint}`, {
    ...options,
    headers,
  });
  if (!response.ok) {
    throw new Error('API request failed');
  }
  return response.json();
};

export const requestOTP = async (email) => {
  const response = await fetch(`${API_BASE_URL}/request_otp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });
  if (!response.ok) {
    throw new Error('OTP request failed');
  }
  return response.json();
};

export const verifyOTP = async (email, otp) => {
  const response = await fetch(`${API_BASE_URL}/verify_otp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, otp }),
  });
  if (!response.ok) {
    throw new Error('OTP verification failed');
  }
  const data = await response.json();
  localStorage.setItem('session_token', data.session_token);
  return data;
};

export const signOut = async () => {
  try {
    await fetchWithAuth('/mediator_sign_out', {
      method: 'POST'
    });

    console.log('Logout successful');
    
    localStorage.removeItem('session_token');
    return { success: true };
  } catch (error) {
    console.error('Logout failed:', error);
    return { success: false, error: error.message };
  }
};


export const updateMediatorProfile = async (updatedProfile) => {
  try {
    const response = await fetchWithAuth('/update_mediator_profile', {
      method: 'POST',
      body: JSON.stringify(updatedProfile),
    });
    return response; // This should be the parsed JSON response
  } catch (error) {
    console.error('Error updating mediator profile:', error);
    throw error;
  }
};

export const fetchCases = () => fetchWithAuth('/cases');

export const fetchMediatorStats = () => fetchWithAuth('/mediator_stats');

export const fetchCaseDetails = (caseId) => fetchWithAuth(`/case_details/${caseId}`);

export const scheduleMeeting = (chatId, dateTime, participantName) =>
  fetchWithAuth('/schedule_meeting', {
    method: 'POST',
    body: JSON.stringify({ chatId, dateTime, participantName }),
  });

export const sendMediationForm = (caseId, agreementText) =>
  fetchWithAuth('/send_mediation_form', {
    method: 'POST',
    body: JSON.stringify({ caseId, agreementText }),
  });

export const requestDocument = (caseId, partyId, documentDetails) =>
  fetchWithAuth('/request_document', {
    method: 'POST',
    body: JSON.stringify({ caseId, partyId, documentDetails }),
  });

export const fetchCaseFiles = (caseId) => fetchWithAuth(`/case_files/${caseId}`);

export const fetchChatMessages = (chatId) => fetchWithAuth(`/chat_messages/${chatId}`);

export const fetchChatOptions = async (caseId) => {
  try {
    const response = await fetchWithAuth(`/chat_options/${caseId}`);
    return Array.isArray(response) ? response : [];
  } catch (error) {
    console.error('Error fetching chat options:', error);
    return [];
  }
};

export const sendChatMessage = (chatId, message) =>
  fetchWithAuth('/send_chat_message', {
    method: 'POST',
    body: JSON.stringify({ chatId, message }),
  });

export const searchNatijaAI = (query) =>
  fetchWithAuth('/search_natija_ai', {
    method: 'POST',
    body: JSON.stringify({ query }),
  });
