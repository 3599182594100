import React, { useState, useEffect } from 'react';
import { Select, Button, DatePicker, TimePicker, Space, message, Typography } from 'antd';
import { ScheduleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { fetchChatOptions, scheduleMeeting } from './api';

const { Option } = Select;
const { Text } = Typography;

const ScheduleMeeting = ({ caseId, onSuccess }) => {
  const [chatOptions, setChatOptions] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([null]);

  useEffect(() => {
    const loadChatOptions = async () => {
      try {
        const options = await fetchChatOptions(caseId);
        setChatOptions(Array.isArray(options) ? options : []);
        if (options.length > 0) {
          setSelectedChat(options[0].id);
        }
      } catch (error) {
        console.error('Error fetching chat options:', error);
        message.error('Failed to load chat options');
      }
    };
    loadChatOptions();
  }, [caseId]);

  const handleAddTimeSlot = () => {
    if (timeSlots.length < 3) {
      setTimeSlots([...timeSlots, null]);
    }
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
  };

  const handleTimeChange = (time, index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index] = time;
    setTimeSlots(newTimeSlots);
  };

  const handleScheduleMeeting = async () => {
    if (selectedDate && selectedChat && timeSlots.some(slot => slot !== null)) {
      const selectedChatName = chatOptions.find(option => option.id === selectedChat)?.name;
      const formattedTimeSlots = timeSlots
        .filter(slot => slot !== null)
        .map(slot => selectedDate.clone().set({
          hour: slot.hour(),
          minute: slot.minute(),
          second: 0,
          millisecond: 0
        }).toISOString());

      try {
        const result = await scheduleMeeting(selectedChat, formattedTimeSlots, selectedChatName);
        if (result.success) {
          message.success('Meeting request sent successfully. Natija will setup the meeting soon.');
          setSelectedDate(null);
          setTimeSlots([null]);
          onSuccess(); // Call the callback function to refresh case details
        } else {
          message.error('Failed to schedule meeting. Please try again.');
        }
      } catch (error) {
        console.error('Error scheduling meeting:', error);
        message.error('Failed to schedule meeting');
      }
    } else {
      message.warning('Please select a party, a date, and at least one time slot for the meeting.');
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Select
        style={{ width: '100%' }}
        placeholder="Select a party for the meeting"
        value={selectedChat}
        onChange={setSelectedChat}
      >
        {chatOptions.map(option => (
          <Option key={option.id} value={option.id}>{option.name}</Option>
        ))}
      </Select>
      <DatePicker
        style={{ width: '100%' }}
        placeholder="Select date for meeting"
        onChange={setSelectedDate}
        value={selectedDate}
      />
      {timeSlots.map((slot, index) => (
        <Space key={index} style={{ width: '100%' }}>
          <TimePicker
            style={{ width: '80%' }}
            format="h:mm A"
            use12Hours
            minuteStep={30}
            placeholder={`Select start time ${index + 1}`}
            onChange={(time) => handleTimeChange(time, index)}
            value={slot}
          />
          {index === timeSlots.length - 1 && timeSlots.length < 3 && (
            <Button onClick={handleAddTimeSlot} icon={<PlusOutlined />} />
          )}
          {timeSlots.length > 1 && (
            <Button onClick={() => handleRemoveTimeSlot(index)} icon={<MinusOutlined />} />
          )}
        </Space>
      ))}
      <Button 
        type="primary" 
        icon={<ScheduleOutlined />} 
        onClick={handleScheduleMeeting}
        disabled={!selectedDate || !selectedChat || !timeSlots.some(slot => slot !== null)}
        style={{ width: '100%' }}
      >
        Schedule Meeting
      </Button>
      <Text type="secondary">
        Note: Meeting duration is set to 1 hour by default.
      </Text>
    </Space>
  );
};

export default ScheduleMeeting;